.main-page-content-container {
    /* position: fixed; */
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgb(195, 188, 188); */
    box-shadow: 10 20 20px rgba(0, 0, 0, 0.5);
    height: 100vh;
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.main-page-content-container::-webkit-scrollbar {
    display: none;
}

.main-page-content-container h1 {
    text-decoration: none;
}

.main-page-content-container footer {
    /* margin: 15px; */
    margin-bottom: 10px ;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    /* border: solid red; */
}

.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 7px;
    /* background: #F39C12; */
    z-index: 2;
}
  

.mp-container {
    display: flex;
    flex-direction: column;
    height: 95vh;
    width: 100vw;
    background-image: linear-gradient(344deg, #E74C3C 0%, #F39C12 40%, #f3ce12 91%);
    overflow-y: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    flex-wrap: wrap;
}

.leftdiv {
    font-size: 80px;
    font-weight: bolder;
    /* border: solid red; */
    line-height: 1.3;
    margin-top: 106px;
    width: 45vw;
    padding: 35px;
    max-height: 70vw;
}

.rightdiv {
    /* background-color: #1ABC9C; */
    font-size: 80px;
    font-weight: bolder;
    padding: 35px;
    /* border: solid red; */
    line-height: 1.3;
    margin-top: 106px;
    width: 30vw;
    height: 90vh;
    justify-content: center;
    align-items: center;
}

.botdiv{
    width: 100%;
    /* height: 182px; */
    /* background-color: #F39C12; */
    font-size: 20px;
    font-style: italic;
    /* padding:6px; */
    line-height: 1.5;
    text-align: left;
    z-index: 1;
    /* border: solid red */
}

.about-links{
    text-decoration: none;
}

.about-links:visited{
    text-decoration: none;
    color: black;
}

.mapbutton{
    width: 242px;
    height: 48px;
    border-radius: 5px;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    background-color:#2980B9 ;
    color: aliceblue;
    border: none;
    font-size: 18px;
}

.mapbutton:hover{
    background-color: #1ABC9C;
}

.splashImg {
    position: relative;
    border-radius: 10px;
    max-height: 35vw;
    /* margin: 30px; */
}

@media screen and (max-width: 800px) {
    .leftdiv, .rightdiv {
        width: 100%;
    }
    .botdiv {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .botdiv {
        display: none;
    }
}

@media screen and (max-height: 730px) {
    .botdiv {
        display: none;
    }
}

.about-us-section {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    /* justify-content: center; */
    /* position: absolute;   */
}

.about-us-name{
    font-size: larger;
    margin-top: 40px;
    margin-bottom: 40px;
}

.profile-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
    border: none;
    border-radius: 8px;
    background-color: #D7DDE6;
}

.profile-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meet-the-team {
    border: none;
    font-size: medium;
    background-color: inherit;
}

.meet-the-team:hover {
    cursor: pointer;
}

.about-us-icons{
    width: 100%;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
}

.about-us-icon{
    width: 48px;
    height: 48px;
    transition: 0.4s;
    padding-left: 6px;
}

.about-us-icon:hover{
    opacity: 20%;
}

.about-us-icons{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    /* justify-content: space-around; */
    /* align-items: center; */
}

.about-us-pic{
    border-radius: 50%;
    height: 9.6rem;
    width: 9.6rem;
    margin-top: 40px;
}
