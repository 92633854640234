.comment-container {
    border-bottom: 0.0625rem solid #d8d9db;
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    position: relative;
}

.update-delete-buttons-container{
    display: flex;
    flex-direction: row;
}

.update-delete-buttons {
    border:none;
    height: 2.4rem;
    width: 2.4rem;
    background-color:#F2F2F2;
}

.icon {
    height: 100%;
    width: 100%;
}

.commenter-container {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 8px;
    position: relative;
    text-align: center;
    width: 3.5rem;
}

.commenter-detail {
    margin: 0 0 4px;
    width: 100%;
    font-weight: bold;
}

.comment-body-container {
    flex-grow: 1;
}


.commenter-avatar {
    align-items: center;
    border-radius: 50%;
    color: white;
    display: flex;
    flex-shrink: 0;
    font-weight: 500;
    height: 2.4rem;
    justify-content: center;
    margin: 0 8px 8px;
    width: 2.4rem;
}

.comment-score-category {
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
}

.comment-score {
    color: #da3743;
    font-size: 14px;
    font-weight: 700;
}

.comment-body-container .comment-score:not(:last-child):after {
    color: #2d333f;
    content: "\B7";
    padding: 0 4px;
}

.comment-CRUD-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#nevermind {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
    cursor: pointer;
}

#nevermind:hover {
    text-decoration: underline;
}

.comment-form-header{
    margin-bottom: 20px;
}

#restProfilecommentsContent {
    height: 250px;
}