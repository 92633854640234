
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.postContentContainer {
  width: 30vw;
  max-width: 600px;
  min-width: 300px;
  height: 30vw;
  max-height: 600px;
  min-height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border-radius: 20px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: white;
}

.postContentContainer:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px #aaaaaa;
  transition: all 0.3s ease;
}

.postContentContainer h1 {
  font-size: 1.7vw;
  font-weight: bold;
  margin-bottom: 1.5vw;
  text-align: left;
  margin-left: 2vw;
}

.postContentContainer h2 {
  font-size: 1.2vw;
  font-weight: bold;
  margin-bottom: 1vw;
  text-align: right;
  margin-right: 2vw;
}

.postContentContainer p {
  font-size: 1.2vw;
  font-weight: normal;
  color: #555555;
  margin-top: 1vw;
  text-align: left;
  margin-left: 2vw;
}

.postContentContainer h1,
.postContentContainer h2 {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.postContentContainer img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  margin: 5vb;
  overflow: hidden;
}

.postContentContainer video {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  margin: 5vb;
  overflow: hidden;
}

.showpage {
  padding-top: 200px;
  padding-bottom: 100px;
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.likes-container {
  display: flex;
  flex-direction: row;
}

.comments-list {
  overflow-y: scroll;
  height: 40vh;
}

.media-container {
    padding-top: 200px;
}

.content-info-container {
  padding-top: 250px;
  margin-left: 40px;
}

@media screen and (max-width: 992px) {
  .postContentContainer {
    padding: 20px;
    z-index: 1;
  }
}

#pop-button {
  transition: transform 0.2s ease-out;
  animation: roll 1s ease-in-out;
  border: none;
}

#pop-button:hover {
  cursor: pointer;
}

@keyframes roll {
  0% {
    transform: translateY(-5px) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
}

#pop-button:active {
  transform: translateY(-10px);
}

#pop-button.roll {
  animation: roll 1s ease-in-out;
}

/* .post-index-item {
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 35vw;
  max-width: 600px;
  min-width: 300px;
  height: 35vw;
  max-height: 600px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .post-index-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px #dddddd;
} */