#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
    position: absolute;
    background-color:white;
    padding: 72px 32px 48px 32px;
    width: 396px;
    height: 673px;
}


#modal-content-large {
    position: absolute;
    background-color:white;
    padding: 144px 32px 48px 32px;
    width: 1200px;
    height: 800px;
}

#modal-content-medium {
    position: absolute;
    background-color:white;
    padding: 72px 32px 48px 32px;
    width: 800px;
    height: 440px;
}

#modal-content-small {
    position: absolute;
    background-color:white;
    padding: 72px 32px 48px 32px;
    width: 100px;
    height: 150px;
}

#modal-content-update-delete {
    position: absolute;
    background-color:white;
    padding: 72px 32px 48px 32px;
    width: 300px;
    height: 150px;
}
