#uploadForm {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: space-evenly;
    /* border: 1px solid red; */
    /* margin-top: 20px; */
}

#uploadForm button {
    cursor: pointer;
    margin: 1px;
    border-radius: 8px;
    padding: 5px;
    border-color: none;
    color: white;
    background-color: #384251;
}

/* #upFile {
    border-radius: 8px;
    padding: 5px;
    border-color: none;
    color: white;
    background-color: #384251;
} */

#content {
    height: 200px;
    width: 200px;
}
