.marker {
    height: 20px;
    line-height: 20px;
    border-radius: 20%;
    font-size: 10px;
    color: #fff;
    text-align: center;
    background: #000;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.postsIndexContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
    width: auto;
    height: auto;
}

#areaShowPageHeader{
    position:fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 115px;
    margin-left: 60px;
    /* border: red solid 1px; */
    height: fit-content;
    width: 100%;
    margin: 0px;
    box-shadow: #000000 0px 0px 10px;
    background-color: rgb(235, 233, 233);
    z-index: 5;
}

#areaShowPageHeader h1 {
    text-decoration: none;
    padding: none;
    margin: none;
}

#areaShowPageHeader button {
    cursor: pointer;
    margin: 1px;
    border-radius: 8px;
    padding: 5px;
    border-color: none;
    color: white;
    background-color: #384251;
}

#areaShowPageHeader button:hover {
    background-color: #2c333b;
}


/* .mapboxgl-popup {
    /* padding-bottom: 50px;
    } */
    
    .mapboxgl-popup-close-button {
    display: none;
    }
    .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
    padding: 10px;
    width: 180px;
    display: flex;
    flex-direction: column;

    }

    a:link { color: black}
    a:visited { text-decoration: none; color: black}
    a:hover { text-decoration: none; color: black}
    a:active { text-decoration: none; color: black}

    .green .mapboxgl-popup-content strong {
    background: #1ABC9C;
    color: #fff;
    margin: -10px -10px 0px -10px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    text-align: center;
    }

    .red .mapboxgl-popup-content strong {
        background: #E74C3C;
        color: #fff;
        margin: -10px -10px 0px -10px;
        padding: 10px;
        border-radius: 3px 3px 0 0;
        font-weight: 700;
        text-align: center;
    }

    .blue .mapboxgl-popup-content strong {
        background: #2980B9;
        color: #fff;
        margin: -10px -10px 0px -10px;
        padding: 10px;
        border-radius: 3px 3px 0 0;
        font-weight: 700;
        text-align: center;
    }

    .orange .mapboxgl-popup-content strong {
        background: #F39C12;
        color: #fff;
        margin: -10px -10px 0px -10px;
        padding: 10px;
        border-radius: 3px 3px 0 0;
        font-weight: 700;
        text-align: center;
    }

    .mapboxgl-popup-content h3 {
        margin: 0;
        padding: 0px;
        font-weight: bolder;
        text-align: center;
        }
    
    
    .mapboxgl-popup-content h4 {
    margin: 0;
    padding: 0px;
    font-weight: 400;
    text-align: center;
    }


    .map-div {
        background-color: rgb(195, 188, 188);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    }

    #areaName {
        color: #E74C3C;
        font-weight: 600;
        font-style: italic;
    }