
.nav-bar {
    list-style-type: none;
    padding: 0px;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #d7dde6;
    height: fit-content;
    width: 100vw;
    margin: 0;
}

.profile-button{
    display: inline-block;
    height: 50px;
}

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 23px;
    margin-bottom: 20px;
    z-index: 1000;
    /* position: fixed; */
}

.nav-button {
    background-color: white;
    font-size: 14px;
    color: black;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0px 16px;
    border: solid;
    border-color: gray;
    border-width: thin;
    border-radius: .1875rem;
    text-decoration: none;
    line-height: 2rem;

}

.nav-button:hover {
    cursor: pointer;
}

.modal-button{
    background-color:#A93233;
    color: white;
    border-radius: .1875rem;
    width: 100%;
    height: 3rem;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.modal-button:hover {
    background-color:  #A22D2B;
}

#modal-content {
    border-radius: .1875rem;
}

.modal-CTA-header {
    margin: 0 0 4px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
}

.modal-CTA-subtext {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 4px 0 32px;
}

.form-field {
        background: #0000;
        border: 0.5px solid;
        border-color: #D8D9DB;
        border-radius: .1875rem;
        box-sizing: border-box;
        color: #2d333f;
        display: block;
        line-height: 24px;
        min-height: 48px;
        padding: 0.75rem 8px;
        width: 100%;
        overflow: visible;
        font-size: 100%;
        transition: 0.2s;
}

.form-field:hover {
    border-color: black;
    border-radius: .1875rem;
    border: 0.5px solid
}

.profile-button-container {
    align-items: center;
    background: #f1f2f4;
    border: 0.0625rem solid #d8d9db;
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
}

.profile-button {
    background: none;
    border: none;
}

.profile-button-container > span {
    display: inline-block;
    font-size: 0;
    height: 1.5rem;
    line-height: 1;
    min-width: 1.5rem;
    width: 1.5rem;
}

.profile-dropdown-container {
    margin-top: 12px;
    padding-bottom: 8px;
    width: 12rem;
    background-color: #fff;
    border-radius: 0.1875rem;
    box-shadow: 0 0 0 0.0625rem #0000001a;
    display: flex;
    flex-direction: column;
    max-height: 70rem;
    right: 0;
    top: 100%;
    /* width: 9.375rem; */
    z-index: 900;
    position: absolute;
}

.sessionLinks-container {
    position: relative;
}

.profile-dropdown-container::before{
    background: #0000;
    border-color: #fff #0000 #0000 #fff;
    border-style: solid;
    border-width: 0.375rem;
    box-shadow: -0.0625rem -0.0625rem 0 0 #00000026;
    content: "";
    display: block;
    height: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: calc(16px - 0.125rem);
    top: -0.375rem;
    transform: rotate(45deg);
    width: 0;
}

.profile-dropdown-container > span {
    font-size: 16px;
    font-weight: 700;
    padding: 16px 16px 8px;
}

.link-container{
    color: #2d333f;
    font-size: 16px;
    padding: 8px 16px;
    text-decoration: none;
}

.profile-dropdown-container > button {
    text-align: left;
    background: none;
	color: inherit;
	border: none;
	padding: 8px 16px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: #A93233
}

.logo-container {
    border: none;
    background-color: white;
    width: 300px;
    height: 90px;
}

.logo {
    max-width: 100%;
    height: 60px
}

.button {
    border-radius: 5px;
    border-width: 0.5px;
    margin-right: 4px;
    /* height: 20px; */
    padding: 4px;
    padding-right: 8px;
    padding-left: 8px;
    transition: 0.2s;
}

#signup-button{
    background-color: #00cf35;
    color: black;
    font-weight: 200;
}

#login-button{
    /* background-color: #00b8ff; */
    background-color: rgb(176, 197, 218);
    color: black
}


.button:hover {
    cursor: pointer;
    opacity: 0.5;
}
